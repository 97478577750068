import defaultAxios from "axios"
import qs from "qs"
import { mapKeysToCamelCase, mapKeysToSnakeCase } from "utils/helpers"
import OAuthService from "../services/oauth_service"
import AppConfigService from "../services/app_config_service"

const NOT_FOUND = "404"
const config = AppConfigService.getConfig()

class EventEmitter {
  constructor() {
    this.callbacks = {}
  }

  on(event, cb) {
    if (!this.callbacks[event]) {
      this.callbacks[event] = []
    }
    this.callbacks[event].push(cb)
  }

  off(event) {
    this.callbacks[event] = []
  }

  emit(event, data) {
    if (!Array.isArray(this.callbacks[event])) {
      return
    }
    this.callbacks[event].forEach((cb) => cb(data))
  }
}

class ApiClient extends EventEmitter {
  errors = {
    NOT_FOUND: { type: NOT_FOUND },
  }

  constructor({ authData, apiUrl = config.API_URL, headers = {} } = {}) {
    super()
    this.axios = defaultAxios
    this.authData = authData
    this.headers = headers
    this.settings = {
      paramsSerializer: (params) =>
        qs.stringify(params, { arrayFormat: "brackets" }),
      baseURL: apiUrl,
    }
    this.userId = null
  }

  setUserId(id) {
    this.userId = id
  }

  setAuthData(authData) {
    this.authData = authData
  }

  async requestManager(request, onSuccessCallback, onErrorCallback) {
    try {
      const response = await request()
      onSuccessCallback(response)
    } catch (e) {
      onErrorCallback(e)
    }
  }

  async get(endpoint, params = {}, opts = {}) {
    return await this.send(
      Object.assign({ method: "get", endpoint: endpoint, params: params }, opts)
    )
  }

  async post(endpoint, payload = {}, opts = {}) {
    return await this.send(
      Object.assign(
        { method: "post", endpoint: endpoint, payload: payload },
        opts
      )
    )
  }

  async put(endpoint, payload = {}, opts = {}) {
    return await this.send(
      Object.assign(
        { method: "put", endpoint: endpoint, payload: payload },
        opts
      )
    )
  }

  async patch(endpoint, payload = {}, opts = {}) {
    return await this.send(
      Object.assign(
        { method: "patch", endpoint: endpoint, payload: payload },
        opts
      )
    )
  }

  async delete(endpoint, payload = {}, opts = {}) {
    return await this.send(
      Object.assign(
        { method: "delete", endpoint: endpoint, payload: payload },
        opts
      )
    )
  }

  async tryRefreshing(error) {
    try {
      const newAuthData = await OAuthService.refreshAccess(
        this.authData.refreshToken
      )
      // it's enough to emit event, auth provider will take care of the rest
      this.emit("token_refreshed", newAuthData)
    } catch (e) {
      // it's enough to emit event, auth provider will take care of the rest
      this.emit("logout")
      throw { errorResponse: error.response }
    }

    return this.send({
      ...error.config,
      endpoint: error.config.url,
      payload: error.config.data,
    })
  }

  async send(request) {
    const {
      method = "get",
      endpoint,
      payload = {},
      headers = {},
      responseType = "json",
      params,
    } = request

    Object.assign(headers, this.headers)

    if (this.authData) {
      headers[
        "Authorization"
      ] = `${this.authData.tokenType} ${this.authData.accessToken}`
    }
    if (this.userId) {
      headers["user-id"] = this.userId
    }

    try {
      const response = await this.axios({
        method,
        headers,
        params,
        responseType,
        url: endpoint,
        data: mapKeysToSnakeCase(payload),
        ...this.settings,
      })

      return mapKeysToCamelCase(response.data)
    } catch (error) {
      if (error.response && error.response.status === 404) {
        throw { ...this.errors.NOT_FOUND, errorResponse: error.response }
      }
      if (error.response?.status === 401 && this.authData) {
        return this.tryRefreshing(error)
      }

      throw { errorResponse: error.response }
    }
  }
}

export default ApiClient
