import { cloneDeep } from "lodash"
import EargoGenerationService from "./eargo_generation_service"

const SOUND_TUNING_KEYS = {
  VOLUME: "VOLUME",
  TREBLE: "TREBLE",
  BASS: "BASS",
}

const SOUND_TUNING_JSON_VALUES_BY_GENERATION = {
  // actual values:   [12,   10,  8,  6,  4,  2, 0, -2, -4, -6, -8, -10, -12]
  // reversed values: [-12, -10, -8, -6, -4, -2, 0,  2,  4,  6,  8,  10,  12]
  // indexes          [0,     1,  2,  3,  4,  5, 6,  7,  8,  9,  10, 11,  12]
  // Values in arrays BASS and TREBLE are **indexes** of the **reversed values** array
  // mobile app reverses it back, because of some misunderstanding in the past
  // fixing it back will take more time, so we live with it for now
  [EargoGenerationService.GENERATIONS.EARGO_FIVE]: {
    [SOUND_TUNING_KEYS.VOLUME]: [-8, -6, -4, -2, 0],
    [SOUND_TUNING_KEYS.BASS]: [4, 5, 6, 7, 8],
    [SOUND_TUNING_KEYS.TREBLE]: [4, 5, 6, 7, 8],
  },
  // actual values:   [12,   10,  8,  6,  4,  2, 0, -2, -4, -6, -8, -10, -12]
  // reversed values: [-12, -10, -8, -6, -4, -2, 0,  2,  4,  6,  8,  10,  12]
  // indexes          [0,     1,  2,  3,  4,  5, 6,  7,  8,  9,  10, 11,  12]
  // Values in arrays BASS and TREBLE are **indexes** of the **reversed values** array
  // mobile app reverses it back, because of some misunderstanding in the past
  // fixing it back will take more time, so we live with it for now
  [EargoGenerationService.GENERATIONS.EARGO_SIX]: {
    [SOUND_TUNING_KEYS.VOLUME]: [-8, -6, -4, -2, 0],
    [SOUND_TUNING_KEYS.BASS]: [4, 5, 6, 7, 8],
    [SOUND_TUNING_KEYS.TREBLE]: [4, 5, 6, 7, 8],
  },

  // actual values   [12,   9,  6,  3, 0, -3, -6, -9, -12]
  // reversed values [-12, -9, -6, -3, 0,  3,  6,  9,  12]
  // indexes         [0,    1,  2,  3, 4,  5,  6,  7,   8]
  // Values in arrays BASS and TREBLE are **indexes** of the **reversed values** array
  // mobile app reverses it back, because of some misunderstanding in the past
  // fixing it back will take more time, so we live with it for now
  [EargoGenerationService.GENERATIONS.EARGO_SEVEN]: {
    [SOUND_TUNING_KEYS.VOLUME]: [-12, -9, -6, -3, 0],
    [SOUND_TUNING_KEYS.BASS]: [2, 3, 4, 5, 6],
    [SOUND_TUNING_KEYS.TREBLE]: [2, 3, 4, 5, 6],
  },
}

const SOUND_TUNING_DISPLAY_VALUES_BY_GENERATION = {
  [EargoGenerationService.GENERATIONS.EARGO_FIVE]: {
    [SOUND_TUNING_KEYS.VOLUME]: [-4, -2, 0, 2, 4],
    [SOUND_TUNING_KEYS.BASS]: [-4, -2, 0, 2, 4],
    [SOUND_TUNING_KEYS.TREBLE]: [-4, -2, 0, 2, 4],
  },
  [EargoGenerationService.GENERATIONS.EARGO_SIX]: {
    [SOUND_TUNING_KEYS.VOLUME]: [-4, -2, 0, 2, 4],
    [SOUND_TUNING_KEYS.BASS]: [-4, -2, 0, 2, 4],
    [SOUND_TUNING_KEYS.TREBLE]: [-4, -2, 0, 2, 4],
  },
  [EargoGenerationService.GENERATIONS.EARGO_SEVEN]: {
    [SOUND_TUNING_KEYS.VOLUME]: [-6, -3, 0, 3, 6],
    [SOUND_TUNING_KEYS.BASS]: [-6, -3, 0, 3, 6],
    [SOUND_TUNING_KEYS.TREBLE]: [-6, -3, 0, 3, 6],
  },
}

const SOUND_TUNING_DEFAULTS_BY_GENERATION = {
  [EargoGenerationService.GENERATIONS.EARGO_FIVE]: {
    [SOUND_TUNING_KEYS.VOLUME]: {
      DEFAULT: 0,
      MIN: -4,
      MAX: 4,
      STEP: 2,
    },
    [SOUND_TUNING_KEYS.BASS]: {
      DEFAULT: 0,
      MIN: -4,
      MAX: 4,
      STEP: 2,
    },
    [SOUND_TUNING_KEYS.TREBLE]: {
      DEFAULT: 0,
      MIN: -4,
      MAX: 4,
      STEP: 2,
    },
    FEEDBACK_ENABLED_VALUE: false,
    FEEDBACK_ENABLED_VALUE_NORMAL: true,
    ECA_VALUE: false,
  },
  [EargoGenerationService.GENERATIONS.EARGO_SIX]: {
    [SOUND_TUNING_KEYS.VOLUME]: {
      DEFAULT: 0,
      MIN: -4,
      MAX: 4,
      STEP: 2,
    },
    [SOUND_TUNING_KEYS.BASS]: {
      DEFAULT: 0,
      MIN: -4,
      MAX: 4,
      STEP: 2,
    },
    [SOUND_TUNING_KEYS.TREBLE]: {
      DEFAULT: 0,
      MIN: -4,
      MAX: 4,
      STEP: 2,
    },
    FEEDBACK_ENABLED_VALUE: false,
    FEEDBACK_ENABLED_VALUE_NORMAL: true,
    ECA_VALUE: true,
  },
  [EargoGenerationService.GENERATIONS.EARGO_SEVEN]: {
    [SOUND_TUNING_KEYS.VOLUME]: {
      DEFAULT: 0,
      MIN: -6,
      MAX: 6,
      STEP: 3,
    },
    [SOUND_TUNING_KEYS.BASS]: {
      DEFAULT: 0,
      MIN: -6,
      MAX: 6,
      STEP: 3,
    },
    [SOUND_TUNING_KEYS.TREBLE]: {
      DEFAULT: 0,
      MIN: -6,
      MAX: 6,
      STEP: 3,
    },
    FEEDBACK_ENABLED_VALUE: false,
    FEEDBACK_ENABLED_VALUE_NORMAL: true,
    ECA_VALUE: true,
  },
}

const INPUT_LABEL_TO_SOUND_TUNING_KEY = {
  soundTuningRightVolume: SOUND_TUNING_KEYS.VOLUME,
  soundTuningLeftVolume: SOUND_TUNING_KEYS.VOLUME,
  soundTuningRightTreble: SOUND_TUNING_KEYS.TREBLE,
  soundTuningLeftTreble: SOUND_TUNING_KEYS.TREBLE,
  soundTuningRightBass: SOUND_TUNING_KEYS.BASS,
  soundTuningLeftBass: SOUND_TUNING_KEYS.BASS,
}

// Feedback cancellation "notch filter"
const FBC_CORRECTION = [
  0,
  0,
  0,
  0,
  -2,
  -4,
  -5,
  -6,
  -7,
  -7,
  -7,
  -7,
  -7,
  -7,
  -7,
  -7,
  -4,
  -2,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
  0,
]

const getFeedbackCorrectionValue = (isEnabled) => {
  if (!isEnabled) {
    return []
  }
  return FBC_CORRECTION
}

export default class SoundTuningService {
  static get soundTuningJSONValues() {
    return SOUND_TUNING_JSON_VALUES_BY_GENERATION[
      EargoGenerationService.getCurrentGeneration()
    ]
  }

  static get soundTuningDisplayValues() {
    return SOUND_TUNING_DISPLAY_VALUES_BY_GENERATION[
      EargoGenerationService.getCurrentGeneration()
    ]
  }

  static get defaultDisplayValues() {
    return SOUND_TUNING_DEFAULTS_BY_GENERATION[
      EargoGenerationService.getCurrentGeneration()
    ]
  }

  /**
   *
   * @param {string} inputLabel - This is an input's label (name)
   * @returns return default "DISPLAY" values for provided input label { MIN: number, MAX: number, STEP: number, DEFAULT: number }
   */
  static getDefaultDisplayValuesForInput(inputLabel) {
    const key = INPUT_LABEL_TO_SOUND_TUNING_KEY[inputLabel]
    return SoundTuningService.defaultDisplayValues[key]
  }

  /**
   * returns a function that checks if input values are not exceeding possible range
   * @param {string} inputLabel - This is an input's label (name)
   * @returns function that is used in 'onInput={}' for input elements used to modify VOLUME/BASS/TREBLE values.
   */
  static displayValuesRangeCheckFactory(inputLabel) {
    const key = INPUT_LABEL_TO_SOUND_TUNING_KEY[inputLabel]
    if (!key) {
      return
    }

    const defaultValues = SoundTuningService.defaultDisplayValues[key]
    return (object) => {
      if (object.target.value > defaultValues.MAX) {
        object.target.value = defaultValues.MAX
      } else if (object.target.value < defaultValues.MIN) {
        object.target.value = defaultValues.MIN
      }
    }
  }

  /**
   *
   * @param {number} value - input value for volume/bass/treble to be converted into "JSON" value
   * @param {string} soundTuningKey - VOLUME | BASS | TREBLE
   * @returns - returns value converted to "JSON" format that is readable by backend. Fallback value is a DEFAULT "JSON" value.
   */
  static convertDisplayToJsonValue(value, soundTuningKey) {
    const indexAt = SoundTuningService.soundTuningDisplayValues[
      soundTuningKey
    ].indexOf(value)
    if (indexAt < 0) {
      const middleIndex = Math.floor(
        SoundTuningService.soundTuningJSONValues[soundTuningKey].length / 2
      )
      return SoundTuningService.soundTuningJSONValues[soundTuningKey][
        middleIndex
      ]
    }
    return SoundTuningService.soundTuningJSONValues[soundTuningKey][indexAt]
  }

  /**
   *
   * @param {number} value - value in "JSON" format,
   * @param {string} soundTuningKey - VOLUME | BASS | TREBLE
   * @returns returns value converted to "DISPLAY" format. Fallback value is a DEFAULT "DISPLAY" value.
   */
  static convertJsonToDisplayValue(value, soundTuningKey) {
    const indexAt = SoundTuningService.soundTuningJSONValues[
      soundTuningKey
    ].indexOf(value)
    if (indexAt < 0) {
      return SoundTuningService.defaultDisplayValues[soundTuningKey].DEFAULT
    }
    return SoundTuningService.soundTuningDisplayValues[soundTuningKey][indexAt]
  }

  /**
   * This method is used before sending data to backend.
   * @param {Object} inputProfiles - Array of hearingProfiles or favourites
   * @returns A deep copy of input argument with converted to "JSON" format sound tuning values
   */
  static convertDisplayToJsonValuesInProfiles(inputProfiles) {
    const profiles = cloneDeep(inputProfiles)

    return profiles.map((profile) => {
      Object.assign(profile, {
        soundTuningLeftVolume: SoundTuningService.convertDisplayToJsonValue(
          profile.soundTuningLeftVolume,
          SOUND_TUNING_KEYS.VOLUME
        ),
        soundTuningRightVolume: SoundTuningService.convertDisplayToJsonValue(
          profile.soundTuningRightVolume,
          SOUND_TUNING_KEYS.VOLUME
        ),
        soundTuningLeftTreble: SoundTuningService.convertDisplayToJsonValue(
          profile.soundTuningLeftTreble,
          SOUND_TUNING_KEYS.TREBLE
        ),
        soundTuningRightTreble: SoundTuningService.convertDisplayToJsonValue(
          profile.soundTuningRightTreble,
          SOUND_TUNING_KEYS.TREBLE
        ),
        soundTuningLeftBass: SoundTuningService.convertDisplayToJsonValue(
          profile.soundTuningLeftBass,
          SOUND_TUNING_KEYS.BASS
        ),
        soundTuningRightBass: SoundTuningService.convertDisplayToJsonValue(
          profile.soundTuningRightBass,
          SOUND_TUNING_KEYS.BASS
        ),
        feedbackCorrectionLeft: getFeedbackCorrectionValue(
          profile.isLeftFeedbackEnabled
        ),
        feedbackCorrectionRight: getFeedbackCorrectionValue(
          profile.isRightFeedbackEnabled
        ),
      })
      return profile
    })
  }

  /**
   * This method is used after fetching data from backend to convert fetched data into user-friendly format.
   * @param {Object} inputProfiles - Array of hearingProfiles or favourites
   * @returns A deep copy of input argument with converted to DISPLAY format sound tuning values
   */
  static convertJsonToDisplayValuesInProfiles(inputProfiles) {
    const profiles = cloneDeep(inputProfiles)

    return profiles.map((profile) => {
      Object.assign(profile, {
        soundTuningLeftVolume: SoundTuningService.convertJsonToDisplayValue(
          profile.soundTuningLeftVolume,
          SOUND_TUNING_KEYS.VOLUME
        ),
        soundTuningRightVolume: SoundTuningService.convertJsonToDisplayValue(
          profile.soundTuningRightVolume,
          SOUND_TUNING_KEYS.VOLUME
        ),
        soundTuningLeftTreble: SoundTuningService.convertJsonToDisplayValue(
          profile.soundTuningLeftTreble,
          SOUND_TUNING_KEYS.TREBLE
        ),
        soundTuningRightTreble: SoundTuningService.convertJsonToDisplayValue(
          profile.soundTuningRightTreble,
          SOUND_TUNING_KEYS.TREBLE
        ),
        soundTuningLeftBass: SoundTuningService.convertJsonToDisplayValue(
          profile.soundTuningLeftBass,
          SOUND_TUNING_KEYS.BASS
        ),
        soundTuningRightBass: SoundTuningService.convertJsonToDisplayValue(
          profile.soundTuningRightBass,
          SOUND_TUNING_KEYS.BASS
        ),
        feedbackCorrectionLeft: getFeedbackCorrectionValue(
          profile.isLeftFeedbackEnabled
        ),
        feedbackCorrectionRight: getFeedbackCorrectionValue(
          profile.isRightFeedbackEnabled
        ),
      })
      return profile
    })
  }
}
