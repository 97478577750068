//configuration for mobile app above 1.2 version
import React from "react"
import {
  useConfigurationStore,
  useDevicesStore,
  useUiStore,
} from "hooks/stores"
import { observer } from "mobx-react"
import {
  NumberInput,
  SelectBaseline,
  SelectNoiseInput,
  SelectFeedbackInput,
  SelectECAInput,
} from "../../input/input"
import { SettingLabel, RowWrapper } from "../hearing_profile_tile_styles"

const HearingProfileTileInputsNewConfig = ({
  hearingProfile,
  index,
  onChange,
}) => {
  const {
    baseProgramLeft,
    baseProgramRight,
    environmentalOffset,
    soundTuningLeftVolume,
    soundTuningRightVolume,
    soundTuningLeftTreble,
    soundTuningRightTreble,
    soundTuningLeftBass,
    soundTuningRightBass,
    isRightFeedbackEnabled,
    isLeftFeedbackEnabled,
    isEcaEnabled,
    noiseReduction,
  } = hearingProfile

  const {
    isHearingAssessmentResInTempConfig,
    checkIfShowNormalOnBaselineSelect,
    applyForReset,
    checkIfIsPresetOfIndex,
  } = useConfigurationStore()

  const {
    isVersionForNewConfigIOS,
    isVersionForNewConfigAndroid,
    isEcaEnabledDevice,
  } = useDevicesStore()

  const { showConfigurationModal } = useUiStore()

  const canShowFBCorrection =
    isVersionForNewConfigIOS("1.3.0") || isVersionForNewConfigAndroid("1.3.0")

  return (
    <>
      <RowWrapper>
        <SettingLabel>Baseline R</SettingLabel>
        <SelectBaseline
          side={"wide"}
          value={baseProgramRight}
          name={`baseProgramRight-${index}-right`}
          edit={showConfigurationModal}
          checkIfIsPresetOfIndex={checkIfIsPresetOfIndex(index)}
          checkIfShowNormalOnBaselineSelect={checkIfShowNormalOnBaselineSelect(
            index
          )}
          disabled={
            environmentalOffset === "NONE" || isHearingAssessmentResInTempConfig
          }
          onChange={onChange}
        />
      </RowWrapper>
      <RowWrapper>
        <SettingLabel>Baseline L</SettingLabel>
        <SelectBaseline
          side={"wide"}
          value={baseProgramLeft}
          name={`baseProgramLeft-${index}-left`}
          edit={showConfigurationModal}
          checkIfIsPresetOfIndex={checkIfIsPresetOfIndex(index)}
          checkIfShowNormalOnBaselineSelect={checkIfShowNormalOnBaselineSelect(
            index
          )}
          disabled={
            environmentalOffset === "NONE" || isHearingAssessmentResInTempConfig
          }
          onChange={onChange}
        />
      </RowWrapper>
      <RowWrapper>
        <SettingLabel>Volume</SettingLabel>
        <NumberInput
          side={"right"}
          value={soundTuningRightVolume}
          name={`soundTuningRightVolume-${index}-right`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
        <NumberInput
          side={"left"}
          value={soundTuningLeftVolume}
          name={`soundTuningLeftVolume-${index}-left`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
      </RowWrapper>
      <RowWrapper>
        <SettingLabel>Treble</SettingLabel>
        <NumberInput
          side={"right"}
          value={soundTuningRightTreble}
          name={`soundTuningRightTreble-${index}-right`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
        <NumberInput
          side={"left"}
          value={soundTuningLeftTreble}
          name={`soundTuningLeftTreble-${index}-left`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
      </RowWrapper>
      <RowWrapper>
        <SettingLabel>Bass</SettingLabel>
        <NumberInput
          side={"right"}
          value={soundTuningRightBass}
          name={`soundTuningRightBass-${index}-right`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
        <NumberInput
          side={"left"}
          value={soundTuningLeftBass}
          name={`soundTuningLeftBass-${index}-left`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
      </RowWrapper>
      {canShowFBCorrection && (
        <RowWrapper>
          <SettingLabel>FB Correction</SettingLabel>
          <SelectFeedbackInput
            side={"right"}
            value={isRightFeedbackEnabled}
            name={`isRightFeedbackEnabled-${index}-right`}
            edit={showConfigurationModal}
            disabled={applyForReset}
            onChange={onChange}
          />
          <SelectFeedbackInput
            side={"left"}
            value={isLeftFeedbackEnabled}
            name={`isLeftFeedbackEnabled-${index}-left`}
            edit={showConfigurationModal}
            disabled={applyForReset}
            onChange={onChange}
          />
        </RowWrapper>
      )}
      {isEcaEnabledDevice && (
        <RowWrapper>
          <SettingLabel>Sound Adjust</SettingLabel>
          <SelectECAInput
            side={"wide"}
            value={isEcaEnabled}
            name={`isEcaEnabled-${index}`}
            edit={showConfigurationModal}
            onChange={onChange}
          />
        </RowWrapper>
      )}
      <RowWrapper>
        <SettingLabel>Noise</SettingLabel>
        <SelectNoiseInput
          side={"wide"}
          value={noiseReduction}
          name={`noiseReduction-${index}`}
          edit={showConfigurationModal}
          onChange={onChange}
        />
      </RowWrapper>
    </>
  )
}

export default observer(HearingProfileTileInputsNewConfig)
