import firstImage from "./1.png"
import secondImage from "./2.png"
import thirdImage from "./3.png"
import forthImage from "./4.png"
import fifthImage from "./5.png"
import sixthImage from "./6.png"
import seventhImage from "./7.png"
import eightImage from "./8.png"
import ninthImage from "./9.png"
import tenthImage from "./10.png"
import eleventhImage from "./11.png"
import twelfthImage from "./12.png"
import thirteenthImage from "./13.png"
import fourteenthImage from "./14.png"
import fifteenthImage from "./15.png"
import sixteenthImage from "./16.png"

const hearingEvaluationImages = (index) => {
  switch (index) {
    case 5:
    case 7:
    case 10:
      return firstImage
    case 25:
    case 35:
    case 39:
      return secondImage
    case 12:
    case 19:
      return thirdImage
    case 9:
    case 14:
    case 16:
    case 22:
    case 23:
      return forthImage
    case 24:
    case 34:
    case 38:
      return fifthImage
    case 31:
    case 36:
    case 40:
    case 33:
    case 47:
    case 46:
    case 50:
      return sixthImage
    case 8:
    case 11:
    case 20:
      return seventhImage
    case 21:
    case 28:
    case 29:
    case 30:
      return eightImage
    case 15:
    case 17:
    case 26:
      return ninthImage
    case 27:
      return tenthImage
    case 37:
    case 54:
      return eleventhImage
    case 41:
    case 43:
    case 45:
    case 48:
    case 49:
    case 51:
      return twelfthImage
    case 42:
    case 44:
      return thirteenthImage
    case 52:
    case 56:
      return fourteenthImage
    case 55:
    case 58:
      return fifteenthImage
    case 4:
    case 6:
    case 18:
      return sixteenthImage
    default:
      return null
  }
}

export { hearingEvaluationImages }
