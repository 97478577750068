import { flow, getEnv, getRoot, types } from "mobx-state-tree"
import HearingEvaluation from "models/hearing_evaluation/hearing_evaluation"
import { arrayMove } from "utils/helpers"
import { compareDesc, parse } from "date-fns"
import { FIRST_AUDIOGRAM_COLUMNS, BISGAARD } from "utils/helpers"
import { toJS } from "mobx"
import { LinearInterpolator } from "../../utils/LinearInterpolator"
import { flattenDeep } from "lodash"
import NewHearingAssessment from "../../models/new_hearing_assessment/new_hearing_assessment"

const INITIAL = "initial"
const LOADING = "loading"
const LOADED = "loaded"
const NOT_FOUND_ERROR = "not_found"
const GET_HEARING_ASSESSMENT_PATH = `/v1/configuration/hearing_assessment`

const HearingEvaluationStore = types
  .model("HearingEvaluationStore", {
    state: types.optional(
      types.enumeration([INITIAL, LOADING, LOADED, NOT_FOUND_ERROR]),
      INITIAL
    ),
    hearingEvaluationHistory: types.optional(types.map(HearingEvaluation), {}),
    activeHearingAssessmentIndex: types.maybeNull(types.number),
    previewIndex: types.maybeNull(types.number),
    tempHearingAssessmentLeftBisgaardIndex: types.maybeNull(types.number),
    tempHearingAssessmentRightBisgaardIndex: types.maybeNull(types.number),
    previewHearingAssessmentLeftBisgaardIndex: types.maybeNull(types.number),
    previewHearingAssessmentRightBisgaardIndex: types.maybeNull(types.number),
    tempHearingAssessmentLeftAudiogramIndex: types.maybeNull(types.number),
    tempHearingAssessmentRightAudiogramIndex: types.maybeNull(types.number),
    tempHearingAssessment: types.maybeNull(types.frozen()),
    isNewSoundMatch: types.optional(types.boolean, false),
    newHearingAssessment: types.maybeNull(NewHearingAssessment),
  })
  .views((self) => {
    return {
      get isLoaded() {
        return self.state === LOADED
      },
      get isDefault() {
        return self.defaultConfig === true
      },
      get isInitial() {
        return self.state === INITIAL
      },
      get defaultNewSoundMatch() {
        return DEFAULT_NEW_SOUND_MATCH
      },
      get checkIfHEisChanged() {
        return (
          self.tempHearingAssessmentLeftBisgaardIndex !== null ||
          self.tempHearingAssessmentRightBisgaardIndex !== null ||
          self.tempHearingAssessmentLeftAudiogramIndex !== null ||
          self.tempHearingAssessmentRightAudiogramIndex !== null
        )
      },
      get hearingEvaluationHistoryList() {
        if (!Array.from(self.hearingEvaluationHistory.values()).length) {
          return []
        }
        const sortedArray = Array.from(
          self.hearingEvaluationHistory.values()
        ).sort(function (a, b) {
          a.lastApplyDate ? (a = new Date(a.lastApplyDate)) : (a = 0)
          b.lastApplyDate ? (b = new Date(b.lastApplyDate)) : (b = 0)
          return compareDesc(a, b)
        })
        const activeIndex = sortedArray.findIndex(
          ({ failed, active, lastApplyDate }) =>
            !!failed && !!active && !!lastApplyDate
        )
        const manualSoundMatchIndex = sortedArray.findIndex(
          ({ id }) => id === "manual"
        )
        if (activeIndex !== -1) arrayMove(sortedArray, activeIndex, 0)
        if (manualSoundMatchIndex !== -1)
          arrayMove(sortedArray, manualSoundMatchIndex, 0)
        return sortedArray
      },
      get findLastApplyActive() {
        return self.hearingEvaluationHistoryList.findIndex((he) => !!he.active)
      },
      get getLastApplyIndex() {
        return self.hearingEvaluationHistoryList.findIndex(
          (hearingEvaluation) =>
            hearingEvaluation.active && hearingEvaluation.lastApplyDate
        )
      },
      get isConfigurationWithHEInvalid() {
        const { isHearingAssessmentResInAny } = getRoot(
          self
        ).configurationStore.deviceConfiguration
        return self.getLastApplyIndex !== -1 && !isHearingAssessmentResInAny
      },
      get leftHearingAssessmentGraph() {
        const { showHearingAssessmentModal } = getRoot(self).uiStore
        if (showHearingAssessmentModal) {
          return self.newHearingAssessment?.leftBisgaardIndex || -1
        }

        if (self.previewHearingAssessmentLeftBisgaardIndex) {
          return self.previewHearingAssessmentLeftBisgaardIndex
        } else if (self.tempHearingAssessmentLeftBisgaardIndex) {
          return self.tempHearingAssessmentLeftBisgaardIndex
        } else if (!getRoot(self).configurationStore.applyForReset) {
          return self.hearingEvaluationHistoryList[self.getLastApplyIndex]
            ?.leftBisgaardIndex
        } else {
          return -1
        }
      },
      get rightHearingAssessmentGraph() {
        const { showHearingAssessmentModal } = getRoot(self).uiStore
        if (showHearingAssessmentModal) {
          return self.newHearingAssessment?.rightBisgaardIndex || -1
        }

        if (self.previewHearingAssessmentRightBisgaardIndex) {
          return self.previewHearingAssessmentRightBisgaardIndex
        } else if (self.tempHearingAssessmentRightBisgaardIndex) {
          return self.tempHearingAssessmentRightBisgaardIndex
        } else if (!getRoot(self).configurationStore.applyForReset) {
          return self.hearingEvaluationHistoryList[self.getLastApplyIndex]
            ?.rightBisgaardIndex
        } else {
          return -1
        }
      },
      getHearingEvaluationIndexById(logId) {
        if (!logId) {
          return -1
        }
        return self.hearingEvaluationHistoryList.findIndex(
          ({ id }) => id === logId
        )
      },
      newHearingAssessmentThresholdsArray(obj, side) {
        return side === "left"
          ? [
              obj.left500HzThreshold,
              obj.left1000HzThreshold,
              obj.left2000HzThreshold,
              obj.left3000HzThreshold,
              obj.left4000HzThreshold,
            ]
          : [
              obj.right500HzThreshold,
              obj.right1000HzThreshold,
              obj.right2000HzThreshold,
              obj.right3000HzThreshold,
              obj.right4000HzThreshold,
            ]
      },
      calculateBisgaardIndex(hearingAssessmentThresholdsArray) {
        const calculateAverageSlope = (audioArray) => {
          return (
            -(
              audioArray[2] -
              audioArray[1] +
              (audioArray[4] - audioArray[2]) +
              (audioArray[5] - audioArray[3]) +
              (audioArray[6] - audioArray[4]) +
              (audioArray[7] - audioArray[5])
            ) / 5
          )
        }

        const xExtrapolated = [2, 3, 4, 4.5, 5]
        const xInterpolated = [1, 2, 3, 4, 4.5, 5, 5.5]

        let audio = toJS(hearingAssessmentThresholdsArray)
        let interpolator = new LinearInterpolator(xExtrapolated, audio)
        let newValues = [1, 5.5].map((value) =>
          interpolator.interpolate(value, true)
        )
        newValues.filter((value) => !isNaN(value))
        audio = flattenDeep([newValues[0], audio, newValues[1]])

        let audioInterpolations = new LinearInterpolator(
          xInterpolated,
          audio
        ).interpolate(3.5, false)
        audio = flattenDeep([
          audio.slice(0, 3),
          [audioInterpolations],
          audio.slice(3, 7),
        ])

        let averageSlope = calculateAverageSlope(audio)
        let ISOaverage = [...audio.slice(1, 3), ...audio.slice(4, 7)].average()

        let orderNum = FIRST_AUDIOGRAM_COLUMNS
        let localBisgaard = BISGAARD.map((value) => value.slice(1, 9))

        let bisgaardAvgSlope = []
        ///Average values from the Bisgaard values corresponding to frequencies at 500, 1000, 2000, 3000 and 4000 Hz
        let bisgaardISOavg = []
        ///The difference between the Bisgaard_ISOavg and the measured thresholds
        let bisgaardISOdiff = []

        let slopeTarget = []
        let targetScore = []

        localBisgaard.forEach((value, index) => {
          let avgSlopeValue =
            -(
              value[2] -
              value[1] +
              value[4] -
              value[2] +
              value[5] -
              value[3] +
              value[6] -
              value[4] +
              value[7] -
              value[5]
            ) / 5
          bisgaardAvgSlope.push([avgSlopeValue])
          let bisgaardISOArrayAvg = [
            ...value.slice(1, 3),
            ...value.slice(4, 7),
          ].average()
          bisgaardISOavg.push(bisgaardISOArrayAvg)
          bisgaardISOdiff.push(Math.abs(ISOaverage - bisgaardISOavg[index]))
          slopeTarget.push(Math.abs(bisgaardAvgSlope[index][0] - averageSlope))
          targetScore.push(bisgaardISOdiff[index] + slopeTarget[index])
        })

        const index = targetScore.indexOf(Math.min(...targetScore))
        return orderNum[index] //bisgaard index
      },
      calculateBisgaardIndexForSide(obj, side) {
        return side === "left"
          ? self.calculateBisgaardIndex(
              self.newHearingAssessmentThresholdsArray(obj, side)
            )
          : self.calculateBisgaardIndex(
              self.newHearingAssessmentThresholdsArray(obj, side)
            )
      },
      get checkIfCanSaveHearingAssessment() {
        return (
          !self.newHearingAssessment?.leftBisgaardIndex ||
          !self.newHearingAssessment?.rightBisgaardIndex
        )
      },
    }
  })
  .actions((self) => {
    const { apiClient } = getEnv(self)

    return {
      startLoading() {
        self.state = LOADING
      },
      endLoading() {
        self.state = LOADED
      },
      setNotFoundError() {
        self.state = NOT_FOUND_ERROR
      },
      setActiveHearingAssessmentIndex(value) {
        self.activeHearingAssessmentIndex = value
      },
      setTempHearingAssessmentLeftBisgaardIndex(value) {
        self.tempHearingAssessmentLeftBisgaardIndex = value
      },
      setTempHearingAssessmentRightBisgaardIndex(value) {
        self.tempHearingAssessmentRightBisgaardIndex = value
      },
      setTempHearingAssessmentLeftAudiogramIndex(value) {
        self.tempHearingAssessmentLeftAudiogramIndex = value
      },
      setTempHearingAssessmentRightAudiogramIndex(value) {
        self.tempHearingAssessmentRightAudiogramIndex = value
      },
      setTempHearingAssessment(value) {
        self.tempHearingAssessment = value
      },
      setNewHearingAssessment(value) {
        self.newHearingAssessment = value
      },
      setPreviewHearingAssessmentLeftBisgaardIndex(value) {
        self.previewHearingAssessmentLeftBisgaardIndex = value
      },
      setPreviewHearingAssessmentRightBisgaardIndex(value) {
        self.previewHearingAssessmentRightBisgaardIndex = value
      },
      setPreviewIndex(value) {
        self.previewIndex = value
      },
      setIsNewSoundMatch(value) {
        self.isNewSoundMatch = value
      },
      handleNewHearingAssessment(value) {
        self.newHearingAssessment = value
      },
      setEnabledConfigurationOfIndex(index) {
        self.setPreviewIndex(index)
        self.setPreviewHearingAssessmentLeftBisgaardIndex(
          self.hearingEvaluationHistoryList[index].leftBisgaardIndex
        )
        self.setPreviewHearingAssessmentRightBisgaardIndex(
          self.hearingEvaluationHistoryList[index].rightBisgaardIndex
        )

        self.setTempHearingAssessmentLeftBisgaardIndex(
          self.hearingEvaluationHistoryList[index].leftBisgaardIndex
        )
        self.setTempHearingAssessmentRightBisgaardIndex(
          self.hearingEvaluationHistoryList[index].rightBisgaardIndex
        )

        const leftAudiogramIndex = FIRST_AUDIOGRAM_COLUMNS.findIndex(
          (number) =>
            number ===
            self.hearingEvaluationHistoryList[index].leftBisgaardIndex
        )
        if (leftAudiogramIndex !== -1) {
          self.setTempHearingAssessmentLeftAudiogramIndex(
            leftAudiogramIndex + 1
          )
        }

        const rightAudiogramIndex = FIRST_AUDIOGRAM_COLUMNS.findIndex(
          (number) =>
            number ===
            self.hearingEvaluationHistoryList[index].rightBisgaardIndex
        )
        if (rightAudiogramIndex !== -1) {
          self.setTempHearingAssessmentRightAudiogramIndex(
            rightAudiogramIndex + 1
          )
        }
        self.setTempHearingAssessment(
          toJS(self.hearingEvaluationHistoryList[index])
        )
        self.setActiveHearingAssessmentIndex(index)
      },
      fetchHearingEvaluation: flow(function* fetch() {
        self.startLoading()
        yield apiClient.requestManager(
          () =>
            apiClient.get(`${GET_HEARING_ASSESSMENT_PATH}`, {
              all_records: true,
            }),
          (response) => {
            response.data.hearingAssessments.forEach(
              self.addHearingEvaluationHistory
            )

            self.endLoading()
          },
          (e) => {
            getRoot(self).uiStore.openNotification(
              `${e}`,
              "error"
            )
            self.setNotFoundError()
          }
        )
      }),
      addHearingEvaluationHistory(attributes) {
        attributes.id = String(attributes.id)
        self.hearingEvaluationHistory.set(String(attributes.id), {
          ...attributes,
        })
      },
      removeHearingEvaluation(id) {
        self.hearingEvaluationHistory.delete(id)
      },
      clearNewHearingAssessment() {
        if (self.isNewSoundMatch) {
          self.removeHearingEvaluation(self.newHearingAssessment.id)
        }
        self.setNewHearingAssessment(null)
        self.setIsNewSoundMatch(false)
      },
      clearHearingEvaluationValues() {
        self.clearPreviewHearingEvaluationValues()
        self.setActiveHearingAssessmentIndex(null)
        self.setTempHearingAssessmentLeftBisgaardIndex(null)
        self.setTempHearingAssessmentRightBisgaardIndex(null)
        self.setTempHearingAssessmentLeftAudiogramIndex(null)
        self.setTempHearingAssessmentRightAudiogramIndex(null)
        self.setTempHearingAssessment(null)
      },
      clearPreviewHearingEvaluationValues() {
        self.setPreviewHearingAssessmentLeftBisgaardIndex(null)
        self.setPreviewHearingAssessmentRightBisgaardIndex(null)
        self.setPreviewIndex(null)
      },
      reset() {
        self.state = INITIAL
        self.hearingEvaluationHistory = {}
        self.tempHearingAssessment = null
        self.tempHearingAssessmentLeftBisgaardIndex = null
        self.tempHearingAssessmentRightBisgaardIndex = null
        self.previewHearingAssessmentLeftBisgaardIndex = null
        self.previewHearingAssessmentRightBisgaardIndex = null
        self.tempHearingAssessmentLeftAudiogramIndex = null
        self.tempHearingAssessmentRightAudiogramIndex = null
      },
    }
  })

const DEFAULT_NEW_SOUND_MATCH = {
  id: "manual",
  leftBisgaardIndex: null,
  rightBisgaardIndex: null,
  left500HzThreshold: 0,
  left1000HzThreshold: 0,
  left2000HzThreshold: 0,
  left3000HzThreshold: 0,
  left4000HzThreshold: 0,
  right500HzThreshold: 0,
  right1000HzThreshold: 0,
  right2000HzThreshold: 0,
  right3000HzThreshold: 0,
  right4000HzThreshold: 0,
  completeDate: null,
  active: true,
  failed: false,
  lastApplyDate: null,
  environmentalOffsets: [],
  manuallyCreated: true,
  createdAt: null,
}

export default HearingEvaluationStore
