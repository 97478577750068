import { types } from "mobx-state-tree"
import RoutesStore from "../routes_store/routes_store"
import LocaleStore from "../locale_store/locale_store"
import SessionStore from "../session_store/session_store"
import ConfigurationStore from "../configuration_store/configuration_store"
import UiStore from "../ui_store/ui_store"
import LogsStore from "../logs_store/logs_store"
import SocketStore from "../socket_store/socket_store"
import JournalStore from "../journal_store/journal_store"
import StatisticsStore from "../statistics_store/statistics_store"
import DevicesStore from "../devices_store/devices_store"
import HearingEvaluationStore from "../hearing_evaluation_store/hearing_evaluation_store"

const AppStore = types.model("AppStore", {
  routesStore: types.optional(RoutesStore, {}),
  localeStore: types.optional(LocaleStore, {}),
  sessionStore: types.optional(SessionStore, {}),
  configurationStore: types.optional(ConfigurationStore, {}),
  uiStore: types.optional(UiStore, {}),
  logsStore: types.optional(LogsStore, {}),
  socketStore: types.optional(SocketStore, {}),
  journalStore: types.optional(JournalStore, {}),
  statisticsStore: types.optional(StatisticsStore, {}),
  devicesStore: types.optional(DevicesStore, {}),
  hearingEvaluationStore: types.optional(HearingEvaluationStore, {}),
})

export default AppStore
