import { useContext } from "react"
import StoreContext from "contexts/store_context"

const useStore = () => {
  return useContext(StoreContext)
}

const useLocaleStore = () => {
  return useContext(StoreContext).localeStore
}

const useRoutesStore = () => {
  return useContext(StoreContext).routesStore
}

const useSessionStore = () => {
  return useContext(StoreContext).sessionStore
}

const useConfigurationStore = () => {
  return useContext(StoreContext).configurationStore
}

const useUiStore = () => {
  return useContext(StoreContext).uiStore
}

const useLogsStore = () => {
  return useContext(StoreContext).logsStore
}

const useSocketStore = () => {
  return useContext(StoreContext).socketStore
}

const useJournalStore = () => {
  return useContext(StoreContext).journalStore
}

const useStatisticsStore = () => {
  return useContext(StoreContext).statisticsStore
}

const useDevicesStore = () => {
  return useContext(StoreContext).devicesStore
}

const useHearingEvaluationStore = () => {
  return useContext(StoreContext).hearingEvaluationStore
}

export {
  useStore,
  useLocaleStore,
  useRoutesStore,
  useSessionStore,
  useConfigurationStore,
  useUiStore,
  useLogsStore,
  useSocketStore,
  useJournalStore,
  useStatisticsStore,
  useDevicesStore,
  useHearingEvaluationStore,
}
