import * as yup from "yup"
import { omit, some } from "lodash"
import SoundTuningService from "../../../services/sound_tuning_service"
import {
  findFavouriteInConfiguration,
  parseObjectForChecks,
} from "utils/helpers"

const prepareDeviceConfigurationSchema = () => {
  const { VOLUME, BASS, TREBLE } = SoundTuningService.defaultDisplayValues
  return yup.object().shape({
    id: yup.string().nullable(),
    isAsymmetricalHlChecked: yup.bool().nullable(),
    userId: yup.string().nullable(),
    favouriteLeft: yup.string().nullable(),
    favouriteLeftSlot: yup.number().nullable(),
    favouriteRight: yup.string().nullable(),
    favouriteRightSlot: yup.number().nullable(),
    hearingAssessmentLeftAudiogramIndex: yup.number().nullable(),
    hearingAssessmentRightAudiogramIndex: yup.number().nullable(),
    favouritesAppliedAt: yup.number().nullable(),
    haAppliedAt: yup.number().nullable(),
    hearingProfiles: yup.array().of(
      yup.object().shape({
        baseProgramLeft: yup.string().nullable(),
        baseProgramRight: yup.string().nullable(),
        deviceConfigurationId: yup.number().nullable(),
        environmentalOffset: yup.string().nullable(),
        id: yup.number(),
        isEmpty: yup.bool(),
        isEnabled: yup.bool(),
        isEcaEnabled: yup.bool(),
        isHearingAssessmentRes: yup.bool().nullable(),
        position: yup.number(),
        appliedAt: yup.number().nullable(),
        soundTuningLeftBass: yup
          .number()
          .required()
          .typeError("you must provide value")
          .max(BASS.MAX)
          .min(BASS.MIN)
          .test(
            "is-input-value-valid",
            `value must be a multiple of the ${BASS.STEP}`,
            (value) => value % BASS.STEP === 0
          ),
        soundTuningLeftTreble: yup
          .number()
          .required()
          .typeError("you must provide value")
          .max(TREBLE.MAX)
          .min(TREBLE.MIN)
          .test(
            "is-input-value-valid",
            `value must be a multiple of the ${TREBLE.STEP}`,
            (value) => value % TREBLE.STEP === 0
          ),
        soundTuningLeftVolume: yup
          .number()
          .required()
          .typeError("you must provide value")
          .max(VOLUME.MAX)
          .min(VOLUME.MIN)
          .test(
            "is-input-value-valid",
            `value must be a multiple of the ${VOLUME.STEP}`,
            (value) => value % VOLUME.STEP === 0
          ),
        soundTuningRightBass: yup
          .number()
          .required()
          .typeError("you must provide value")
          .max(BASS.MAX)
          .min(BASS.MIN)
          .test(
            "is-input-value-valid",
            `value must be a multiple of the ${BASS.STEP}`,
            (value) => value % BASS.STEP === 0
          ),
        soundTuningRightTreble: yup
          .number()
          .required()
          .typeError("you must provide value")
          .max(TREBLE.MAX)
          .min(TREBLE.MIN)
          .test(
            "is-input-value-valid",
            `value must be a multiple of the ${TREBLE.STEP}`,
            (value) => value % TREBLE.STEP === 0
          ),
        soundTuningRightVolume: yup
          .number()
          .required()
          .typeError("you must provide value")
          .max(VOLUME.MAX)
          .min(VOLUME.MIN)
          .test(
            "is-input-value-valid",
            `value must be a multiple of the ${VOLUME.STEP}`,
            (value) => value % VOLUME.STEP === 0
          ),
      })
    ),
  })
}

const validateOnInput = async (newDeviceConfiguration, target) => {
  try {
    await prepareDeviceConfigurationSchema().validate(newDeviceConfiguration, {
      abortEarly: false,
    })
    target.style.border = "1px solid rgba(51,51,51,0.1)"
  } catch (e) {
    const matches = document.querySelectorAll(".newConfigurationInput")
    matches.forEach((match) => {
      match.style.border = "1px solid rgba(51,51,51,0.1)"
    })
    const regexLogs = []
    e.inner.forEach((error) => {
      const regex = error.path.match(/\[(.*?)\]|[^.]+$/gim)
      regexLogs.push(
        document.getElementsByClassName(
          `${regex[1]}-${Array.from(regex[0])[1]}`
        )[1]
      )
    })
    regexLogs.forEach((match) => {
      match.style.border = "1px solid rgb(255,51,51)"
    })
  }
}

const handleSelectInputs = (
  selectInputs,
  tempDeviceConfiguration,
  isDefault
) => {
  selectInputs.forEach((arrayInput) =>
    arrayInput.forEach((input) => (input.disabled = true))
  )

  const handleDisablingUsedOnes = () => {
    tempDeviceConfiguration.hearingProfiles.forEach((profile) => {
      selectInputs.forEach((arrayInput) => {
        const index = arrayInput.findIndex(
          (input) =>
            input.value === profile.baseProgramLeft ||
            input.value === profile.environmentalOffset
        )
        if (profile.isEmpty) {
          return
        }
        if (!profile.isHearingAssessmentRes) {
          arrayInput[index].disabled = true
        }
      })
    })
  }

  const {
    foundFavouriteLeft,
    foundFavouriteRight,
  } = findFavouriteInConfiguration(tempDeviceConfiguration)

  if (tempDeviceConfiguration.hearingProfiles.length === 0) {
    selectInputs.forEach((arrayInput) =>
      arrayInput.forEach((input) => (input.disabled = true))
    )
    return
  }

  if (
    !some(
      tempDeviceConfiguration.hearingProfiles,
      (profile) => profile.environmentalOffset === "NONE"
    )
  ) {
    selectInputs.forEach((arrayInput) => {
      arrayInput.forEach((input) => {
        input.disabled = ![
          "PROGRAM_A",
          "PROGRAM_B",
          "PROGRAM_C",
          "PROGRAM_D",
        ].includes(input.value)
      })
    })
  }

  const normalProgram = tempDeviceConfiguration.hearingProfiles.filter(
    (profile) => profile.isHearingAssessmentRes === true
  )[0]

  if (normalProgram) {
    selectInputs.forEach((arrayInput, index) => {
      if (index + 1 === normalProgram.position) {
        const hearingIndex = tempDeviceConfiguration.hearingProfiles.findIndex(
          (profile) => {
            return profile.isHearingAssessmentRes === true
          }
        )
        if (hearingIndex === -1) {
          arrayInput.forEach((input) => (input.disabled = true))
        }
      } else {
        arrayInput.forEach((input) => (input.disabled = false))
      }
    })
    return handleDisablingUsedOnes()
  } else if (
    tempDeviceConfiguration.favouriteRightSlot ||
    foundFavouriteRight ||
    tempDeviceConfiguration.favouriteLeftSlot ||
    foundFavouriteRight
  ) {
    selectInputs.forEach((arrayInput, index) => {
      if (
        (index + 1 === tempDeviceConfiguration.favouriteRightSlot ||
          index + 1 === tempDeviceConfiguration.favouriteLeftSlot) &&
        (index === foundFavouriteLeft || index === foundFavouriteRight)
      ) {
        const hearingIndex = tempDeviceConfiguration.hearingProfiles.findIndex(
          (profile) => {
            return profile.isHearingAssessmentRes === true
          }
        )
        if (hearingIndex === -1) {
          arrayInput.forEach((input) => (input.disabled = true))
        }
      } else {
        arrayInput.forEach((input) => (input.disabled = false))
        const indexLeft = arrayInput.findIndex(
          (input) =>
            input.value ===
            tempDeviceConfiguration.hearingProfiles[foundFavouriteRight]
              ?.baseProgramRight
        )

        if (indexLeft !== -1) {
          arrayInput[indexLeft].disabled = true
        }
        const indexRight = arrayInput.findIndex(
          (input) =>
            input.value ===
            tempDeviceConfiguration.hearingProfiles[foundFavouriteLeft]
              ?.baseProgramLeft
        )

        if (indexRight !== -1) {
          arrayInput[indexRight].disabled = true
        }
      }
    })
    return handleDisablingUsedOnes()
  }

  if (
    isDefault ||
    !tempDeviceConfiguration.favouriteLeftSlot ||
    !tempDeviceConfiguration.favouriteRightSlot ||
    !foundFavouriteRight ||
    !foundFavouriteLeft
  ) {
    return selectInputs.forEach((arrayInput) =>
      arrayInput.forEach((input) => (input.disabled = true))
    )
  }

  handleDisablingUsedOnes()
}

const handleSelectInputsOld = (
  selectInputs,
  tempDeviceConfiguration,
  isDefault
) => {
  selectInputs.forEach((arrayInput) =>
    arrayInput.forEach((input) => (input.disabled = true))
  )

  const handleDisablingUsedOnes = () => {
    tempDeviceConfiguration.hearingProfiles.forEach((profile) => {
      selectInputs.forEach((arrayInput) => {
        if (profile.isEmpty) {
          return
        }

        const index = arrayInput.findIndex(
          (input) =>
            input.value === profile.baseProgramLeft ||
            input.value === profile.environmentalOffset
        )

        if (!profile.isHearingAssessmentRes) {
          arrayInput[index].disabled = true
        }
      })
    })
  }

  if (tempDeviceConfiguration.hearingProfiles.length === 0) {
    return selectInputs.forEach((arrayInput) =>
      arrayInput.forEach((input) => (input.disabled = true))
    )
  }

  const normalProgram = tempDeviceConfiguration.hearingProfiles.filter(
    (profile) => profile.isHearingAssessmentRes === true
  )[0]

  if (normalProgram) {
    selectInputs.forEach((arrayInput, index) => {
      if (index + 1 === normalProgram.position) {
        const hearingIndex = tempDeviceConfiguration.hearingProfiles.findIndex(
          (profile) => {
            return profile.isHearingAssessmentRes === true
          }
        )
        if (hearingIndex === -1) {
          arrayInput.forEach((input) => (input.disabled = true))
        }
      } else {
        arrayInput.forEach((input) => (input.disabled = false))
      }
    })
    return handleDisablingUsedOnes()
  } else if (
    tempDeviceConfiguration.favouriteRightSlot !== null ||
    tempDeviceConfiguration.favouriteLeftSlot !== null
  ) {
    selectInputs.forEach((arrayInput, index) => {
      if (
        index + 1 === tempDeviceConfiguration.favouriteRightSlot ||
        index + 1 === tempDeviceConfiguration.favouriteLeftSlot
      ) {
        const hearingIndex = tempDeviceConfiguration.hearingProfiles.findIndex(
          (profile) => {
            return profile.isHearingAssessmentRes === true
          }
        )
        if (hearingIndex === -1) {
          arrayInput.forEach((input) => (input.disabled = true))
        }
      } else {
        arrayInput.forEach((input) => (input.disabled = false))
        const indexLeft = arrayInput.findIndex(
          (input) =>
            input.value ===
            tempDeviceConfiguration.hearingProfiles[
              tempDeviceConfiguration.favouriteRightSlot
            ]?.baseProgramRight
        )

        if (indexLeft !== -1) {
          arrayInput[indexLeft].disabled = true
        }
        const indexRight = arrayInput.findIndex(
          (input) =>
            input.value ===
            tempDeviceConfiguration.hearingProfiles[
              tempDeviceConfiguration.favouriteLeftSlot
            ]?.baseProgramLeft
        )

        if (indexRight !== -1) {
          arrayInput[indexRight].disabled = true
        }
      }
    })
    return handleDisablingUsedOnes()
  }

  if (isDefault) {
    return selectInputs.forEach((arrayInput) =>
      arrayInput.forEach((input) => (input.disabled = true))
    )
  }
  handleDisablingUsedOnes()
}

const checkForChangesInProfiles = (
  plainDeviceConfiguration,
  newDeviceConfiguration
) => {
  return (
    parseObjectForChecks(plainDeviceConfiguration.hearingProfiles) !==
    parseObjectForChecks(newDeviceConfiguration.hearingProfiles)
  )
}

const checkForChangesInFavorites = (
  plainDeviceConfiguration,
  newDeviceConfiguration
) => {
  const oldConfig = omit(plainDeviceConfiguration, [
    "hearingProfiles",
    "favouritesAppliedAt",
    "favourites",
  ])
  const newConfig = omit(newDeviceConfiguration, [
    "hearingProfiles",
    "favouritesAppliedAt",
    "favourites",
  ])

  return parseObjectForChecks(oldConfig) !== parseObjectForChecks(newConfig)
}

const checkIfRemovedFavourites = (
  plainDeviceConfiguration,
  newDeviceConfiguration
) => {
  const oldConfig = omit(plainDeviceConfiguration, [
    "hearingProfiles",
    "configurationHistories",
    "favouritesAppliedAt",
    "favourites",
  ])
  const newConfig = omit(newDeviceConfiguration, [
    "hearingProfiles",
    "favouritesAppliedAt",
    "favourites",
  ])

  const isNormal = newDeviceConfiguration?.hearingProfiles.some(
    (hearingProfile) => hearingProfile.isHearingAssessmentRes === true
  )

  return (
    !isNormal &&
    ((oldConfig.favouriteRightSlot !== null &&
      newConfig.favouriteRightSlot === null) ||
      (oldConfig.favouriteLeftSlot !== null &&
        newConfig.favouriteLeftSlot === null))
  )
}

const checkIfAllDisabled = (newDeviceConfiguration) => {
  return (
    newDeviceConfiguration.hearingProfiles.filter(
      (profile) => profile.isEnabled === true
    ).length === 0
  )
}

const checkForUniquePrograms = (newDeviceConfiguration) => {
  const newDeviceConfigurationWithPresets = newDeviceConfiguration.hearingProfiles.filter(
    (hearingProfile) =>
      hearingProfile.environmentalOffset === "NONE" &&
      !hearingProfile.isEmpty &&
      !hearingProfile.isHearingAssessmentRes
  )

  const newDeviceConfigurationWithoutNone = newDeviceConfiguration.hearingProfiles.filter(
    (hearingProfile) =>
      hearingProfile.environmentalOffset !== "NONE" &&
      !hearingProfile.isEmpty &&
      !hearingProfile.isHearingAssessmentRes
  )

  const checkWithPresets = new Set(
    newDeviceConfigurationWithPresets.map((v) => v.baseProgramRight)
  )

  const checkWithEnvironmentalOffset = new Set(
    newDeviceConfigurationWithoutNone.map((v) => v.environmentalOffset)
  )

  const result =
    checkWithPresets.size < newDeviceConfigurationWithPresets.length ||
    checkWithEnvironmentalOffset.size < newDeviceConfigurationWithoutNone.length

  let data = null
  if (checkWithPresets.size < newDeviceConfigurationWithPresets.length) {
    data = checkWithPresets.values().next().value
  } else if (
    checkWithEnvironmentalOffset.size < newDeviceConfigurationWithoutNone.length
  ) {
    data = checkWithEnvironmentalOffset.values().next().value
  }
  return {
    result,
    data,
  }
}

export {
  prepareDeviceConfigurationSchema,
  validateOnInput,
  handleSelectInputs,
  handleSelectInputsOld,
  checkForChangesInProfiles,
  checkForUniquePrograms,
  checkIfAllDisabled,
  checkForChangesInFavorites,
  checkIfRemovedFavourites,
}
