import React from "react"
import { Grid } from "@material-ui/core"
import { useDevicesStore } from "hooks/stores"
import { observer } from "mobx-react"
import { StyledPaper } from "./components/hearing_profile_tile_styles"
import HearingProfileTileEmpty from "./components/hearing_profile_tile_empty"
import HearingProfileTileDisabled from "./components/hearing_profile_tile_disabled"
import HearingProfileTileHeader from "./components/hearing_profile_tile_header"
import HearingProfileTileInputsNewConfig from "./components/new_config/hearing_profile_tile_inputs_new_config"
import HearingProfileTileInputsOldConfig from "./components/old_config/hearing_profile_tile_inputs_old_config"

const HearingProfileTile = ({
  hearingProfile,
  newFavouriteLeftSlot,
  newFavouriteRightSlot,
  index,
  onChange,
}) => {
  const { isEmpty, isEnabled } = hearingProfile

  const { isNewConfig } = useDevicesStore()

  if (isEmpty) {
    return (
      <HearingProfileTileEmpty
        isEmpty={isEmpty}
        isEnabled={isEnabled}
        index={index}
        onChange={onChange}
      />
    )
  }

  if (!isEnabled) {
    return (
      <HearingProfileTileDisabled
        isEnabled={isEnabled}
        index={index}
        onChange={onChange}
      />
    )
  }

  return (
    <Grid item xs={12} md={6} key={index}>
      <StyledPaper>
        <form>
          <HearingProfileTileHeader
            hearingProfile={hearingProfile}
            newFavouriteLeftSlot={newFavouriteLeftSlot}
            newFavouriteRightSlot={newFavouriteRightSlot}
            index={index}
            onChange={onChange}
          />
          {isNewConfig ? (
            <HearingProfileTileInputsNewConfig
              hearingProfile={hearingProfile}
              index={index}
              onChange={onChange}
            />
          ) : (
            <HearingProfileTileInputsOldConfig
              hearingProfile={hearingProfile}
              index={index}
              onChange={onChange}
            />
          )}
        </form>
      </StyledPaper>
    </Grid>
  )
}

export default observer(HearingProfileTile)
