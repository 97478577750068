import { types } from "mobx-state-tree"
import EargoSystem from "../eargo_system/eargo_system"
import EargoGenerationService from "../../services/eargo_generation_service"

const User = types
  .model("User", {
    id: types.identifier,
    email: types.maybeNull(types.string),
    eusAddress: types.maybeNull(types.number),
    notificationTokens: types.maybeNull(types.frozen()),
    eargoSystems: types.optional(types.map(EargoSystem), {}),
    generation: types.optional(
      types.enumeration(Object.values(EargoGenerationService.GENERATIONS)),
      EargoGenerationService.GENERATIONS.EARGO_FIVE
    ),
    customer: types.frozen(),
  })
  .views((self) => {
    return {
      getEargoSystemByUserId(id) {
        return self.eargoSystems.get(id)
      },
      get eargoSystemsList() {
        return Array.from(self.eargoSystems.values())
      },
    }
  })
  .actions((self) => {
    return {
      addEargoSystem({
        eargoDevices,
        isActive,
        userId,
        serialNumber,
        statistics,
        id,
      }) {
        self.eargoSystems.set(String(id), {
          id: String(id),
          isActive,
          serialNumber,
          userId,
          statistics,
        })
        if (eargoDevices.length) {
          const eargoSystem = self.getEargoSystemByUserId(String(id))
          eargoDevices.forEach((device) => eargoSystem.addEargoDevice(device))
        }
      },
    }
  })

export default User
