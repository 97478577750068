class LinearInterpolator {
  constructor(xData, yData) {
    this.xData = xData
    this.yData = yData
  }

  interpolate(value, isExtrapolating) {
    const size = this.xData.length

    let index = 0
    if (value >= this.xData[size - 2]) {
      index = size - 2
    } else {
      while (value > this.xData[index + 1]) {
        index += 1
      }
    }

    let xLValue = this.xData[index]
    let yLValue = this.yData[index]
    let xRValue = this.xData[index + 1]
    let yRValue = this.yData[index + 1]

    if (!isExtrapolating) {
      if (value < xLValue) {
        yRValue = yLValue
      }
      if (value > xRValue) {
        yLValue = yRValue
      }
    }

    let dydx = (yRValue - yLValue) / (xRValue - xLValue)
    return yLValue + dydx * (value - xLValue)
  }
}

export { LinearInterpolator }
