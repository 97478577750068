import React, { useState } from "react"
import { observer } from "mobx-react"
import styled from "styled-components"
import { Menu, MenuItem, ListItem } from "@material-ui/core"
import { NotificationBell, AppRatingStar } from "images/svg"
import colors from "styles/colors"
import { useDevicesStore, useSessionStore } from "hooks/stores"
import Loading from "components/loading/loading"

const ActionWrapper = styled.div`
  background-color: ${colors.blue};
  width: 40px;
  height: 40px;
  border-radius: 25px;
  &:hover {
    cursor: pointer;
  }
  &:before {
    content: "…";
    transform: rotate(90deg);
    color: white;
    position: relative;
    font-size: 32px;
    left: calc(50% - 2px);
    top: 7px;
    display: inline-flex;
  }
`

const StyledMenu = styled(Menu)`
  && {
    .MuiMenu-paper {
      min-width: 150px;
      border-radius: 4px;
      background: white;
      color: black;

      .MuiListItem-root {
        margin: 14px;

        svg {
          margin-left: auto;
        }
      }

      .MuiListItem-root:not(:first-child) {
        border-top: 1px solid rgba(55, 55, 55, 0.16);

        :not(:last-child) {
          padding-top: 16px;
        }
      }
    }
  }
`

const Wrapper = styled.div`
  display: inline-block;
`

const TextWrapper = styled.div`
  margin-right: 15px;
`

const ControlWrapper = styled.div``

const InputWrapper = styled.div`
  margin: 20px auto 0px;
  height: 40px;
`

const ApplyButton = styled.button`
  font-weight: 800;
  width: 195px;
  height: 32px;
  line-height: 24px;
  margin: 12px auto 0px;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: ${colors.white};
  background-color: ${({ disabled }) =>
    disabled ? colors.grey : colors.orange};
  border: none;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  }
`

const StaticValue = styled.div`
  display: inline;
  position: absolute;
  top: 14px;
  font-size: 16px;
  color: black;
  right: 30%;
`

const InputValue = styled.input`
  position: absolute;
  width: 60px;
  height: 40px;
  border: 1px solid rgba(51, 51, 51, 0.22);
  box-sizing: border-box;
  border-radius: 4px;
  color: ${colors.numberBlue};
  right: 16px;
  top: 20px;

  &:focus {
    outline: none;
  }
`

const inputCheck = (object) => {
  if (object.target.value > 7) {
    object.target.value = 7
  } else if (object.target.value < 0) {
    object.target.value = 0
  }
}

const NumberInput = ({
  value,
  defaultValue,
  edit,
  name,
  onChange,
  disabled,
}) => {
  return (
    <>
      {
        <StaticValue onInput={inputCheck} onChange={onChange}>
          {value}
        </StaticValue>
      }
      <InputValue
        disabled={disabled}
        value={value}
        defaultValue={defaultValue}
        step={1}
        type="number"
        className={`${name} newConfigurationInput`}
        name={name}
        onChange={onChange}
        onInput={inputCheck}
      />
    </>
  )
}

const ContextMenu = () => {
  const { sendDiagnosticPN, sendAppRatingRequestPN } = useDevicesStore()
  const { eusAddress, updateEUSAddress } = useSessionStore()

  const [anchorEl, setAnchorEl] = useState(null)
  const [applyDisabled, setApplyDisabled] = useState(true)
  const [currentEusAddress, setCurrentEusAddress] = useState()

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  const handleChange = (e) => {
    const value = parseInt(e.target.value)

    if (!value && isNaN(value)) {
      setApplyDisabled(true)
      return
    }

    setApplyDisabled(eusAddress === value)
    setCurrentEusAddress(value)
  }

  const handleApply = (newAddress) => {
    if (!newAddress) return

    setAnchorEl(null)
    setApplyDisabled(true)
    updateEUSAddress(currentEusAddress)
  }

  return (
    <Wrapper>
      <ActionWrapper id={`context-menu-wrapper`} onClick={handleClick} />
      <ListItem>
        <StyledMenu
          id={`context-menu`}
          anchorEl={anchorEl}
          open={Boolean(anchorEl && anchorEl.id === `context-menu-wrapper`)}
          onClose={handleClose}
        >
          <MenuItem
            onClick={async () => {
              await sendDiagnosticPN()
            }}
          >
            <TextWrapper> Get serial number </TextWrapper> <NotificationBell />
          </MenuItem>
          <MenuItem
            onClick={async () => {
              await sendAppRatingRequestPN()
            }}
          >
            <TextWrapper> App rating </TextWrapper> <AppRatingStar />
          </MenuItem>
          <MenuItem>
            <ControlWrapper>
              <InputWrapper>
                EUS Address
                <NumberInput
                  defaultValue={eusAddress}
                  name={`user-eus-address`}
                  edit={false}
                  onChange={handleChange}
                />
              </InputWrapper>
              <ApplyButton disabled={applyDisabled} onClick={handleApply}>
                Apply
              </ApplyButton>
            </ControlWrapper>
          </MenuItem>
        </StyledMenu>
      </ListItem>
    </Wrapper>
  )
}

export default observer(ContextMenu)
