import { types, getEnv, flow, getRoot } from "mobx-state-tree"
import User from "models/user/user"
import SalesforceUser from "models/salesforce_user/salesforce_user"
import { compact, omit } from "lodash"
import MobileDevice from "models/mobile_device/mobile_device"
import FirmwareVersions from "models/firmware_versions/firmware_versions"
import { compareVersions } from "utils/helpers"
import AppConfigService from "../../services/app_config_service"
import EargoGenerationService from "../../services/eargo_generation_service"

const INITIAL = "initial"
const LOADING = "loading"
const LOADED = "loaded"
const NOT_FOUND_ERROR = "not_found"
const ENDPOINT_API_PATH = `/v1/php/session`
const GET_API_FIRMWARE_VERSIONS = `/v1/firmware`
const UPDATE_USER_EUSADDRESS_PATH = `/v1/user/eus_address`

const config = AppConfigService.getConfig()

const SessionStore = types
  .model("SessionStore", {
    state: types.optional(
      types.enumeration([INITIAL, LOADING, LOADED, NOT_FOUND_ERROR]),
      INITIAL
    ),
    users: types.optional(types.map(User), {}),
    userMobileDevices: types.optional(types.map(MobileDevice), {}),
    salesforceUser: types.maybeNull(types.optional(SalesforceUser, {})),
    currentUserId: types.optional(types.string, ""),
    currentEusAddress: types.maybeNull(types.number),
    firmwareVersions: types.maybeNull(types.optional(FirmwareVersions, {})),
  })
  .views((self) => {
    return {
      get isLoaded() {
        return self.state === LOADED
      },
      get isInitial() {
        return self.state === INITIAL
      },
      get isNotFoundError() {
        return self.state === NOT_FOUND_ERROR
      },
      get usersList() {
        return Array.from(self.users.values()).filter(
          (user) =>
            user.generation === EargoGenerationService.getCurrentGeneration() //default for now
        )
      },
      get userId() {
        return self.currentUserId || self.salesforceUser?.id
      },
      get eusAddress() {
        return self.currentEusAddress || self.salesforceUser?.eusAddress
      },
      get eargoUserSystems() {
        return self.salesforceUser?.eargoSystemsList.length
          ? self.salesforceUser.eargoSystemsList
          : self.getUserById(self.userId)?.eargoSystemsList
      },
      get canFetchOtherData() {
        const currentGeneration = EargoGenerationService.getCurrentGeneration()
        return (
          self.salesforceUser?.id &&
          self.salesforceUser?.generation === currentGeneration
        )
      },
      get eargoHiArrayList() {
        const HIArray = []
        if (!self.eargoUserSystems) return []
        self.eargoUserSystems.forEach((system) => {
          system.eargoDevicesList.forEach((device) => {
            if (
              device.deviceType === "hi_left" ||
              device.deviceType === "hi_right"
            ) {
              HIArray.push(device.firmwareVersion)
            }
          })
        })
        return compact(HIArray)
      },
      isVersionForMaskEnv(FWVersion) {
        if (self.isLoaded && self.eargoHiArrayList.length === 0) {
          return false
        }
        return !!self.eargoHiArrayList?.filter((HI) => {
          return compareVersions(HI, FWVersion) >= 0
        }).length
      },

      get eargoUserDeactivatedDevices() {
        const deactivatedDevices = []
        if (!self.eargoUserSystems) return []
        const systems = self.eargoUserSystems
        systems.forEach((system) => {
          system.eargoDevicesList.forEach((device) => {
            if (device.status === "DEACTIVATED") {
              deactivatedDevices.push(device)
            }
          })
        })

        return deactivatedDevices
      },
      getUserById(id) {
        return self.users.get(id)
      },
    }
  })
  .actions((self) => {
    const { apiClient } = getEnv(self)

    return {
      startLoading() {
        self.state = LOADING
      },
      endLoading() {
        self.state = LOADED
      },
      setNotFoundError() {
        self.state = NOT_FOUND_ERROR
      },
      setCurrentUserId(id) {
        self.currentUserId = id
      },
      setEusAddress(eusAddress) {
        self.currentEusAddress = eusAddress
      },
      fetchFirmwareVersions: flow(function* fetchFirmwareVersions() {
        yield apiClient.requestManager(
          async () =>
            await apiClient.get(
              `${GET_API_FIRMWARE_VERSIONS}/?generation=${EargoGenerationService.getCurrentGeneration()}`
            ),
          (response) => {
            self.addFirmwareVersions(response.data)
          },
          (e) => {
            getRoot(self).uiStore.openNotification(`${e}`, "error")
            self.setNotFoundError()
          }
        )
      }),
      fetch: flow(function* fetch() {
        const {
          startLoading: statisticsStartLoading,
          endLoading: statisticsEndLoading,
        } = getRoot(self).statisticsStore
        statisticsStartLoading()
        self.startLoading()
        const { search } = getEnv(self).history.location
        yield self.fetchFirmwareVersions()
        yield apiClient.requestManager(
          () =>
            apiClient.get(
              `${ENDPOINT_API_PATH}${
                search ? search : "?accountId="
              }&generation=${EargoGenerationService.getCurrentGeneration()}`
            ),
          (response) => {
            response.data.salesforceUser
              ? self.addSalesforceUser(response.data.salesforceUser)
              : statisticsEndLoading()
            response.data.users.forEach(self.addUser)
            self.endLoading()
          },
          (e) => {
            getRoot(self).uiStore.openNotification(`${e}`, "error")
            self.setNotFoundError()
          }
        )

        statisticsEndLoading()
        self.endLoading()
      }),
      updateEUSAddress: flow(function* updateEUSAddress(newAddress) {
        self.startLoading()
        const userId = getRoot(self).sessionStore?.userId
        if (!userId) return

        let body = null
        body = { eus_address: newAddress }
        if (!body) return

        yield apiClient.requestManager(
          () => apiClient.patch(`${UPDATE_USER_EUSADDRESS_PATH}`, body),
          () => {
            getRoot(self).uiStore.openNotification(
              `New EUS address saved. BLE programming required for activation.`,
              "success"
            )
          },
          (e) => {
            getRoot(self).uiStore.openNotification(`${e}`, "error")
            self.setNotFoundError()
          }
        )
        yield self.fetch()
        self.endLoading()
      }),
      addSalesforceUser(attributes) {
        const eargoSystems = attributes.eargoSystems
        attributes.id = String(attributes.id)
        attributes = omit(attributes, ["eargoSystems"])

        self.salesforceUser = { ...attributes }
        if (eargoSystems.length) {
          eargoSystems.forEach((device) =>
            self.salesforceUser.addEargoSystem(device)
          )
        }
        self.setEusAddress(attributes.eusAddress)
        self.setCurrentUserId(attributes.id)
        apiClient.setUserId(attributes.id)
      },
      addUser(attributes) {
        const eargoSystems = attributes.eargoSystems
        attributes.id = String(attributes.id)
        attributes = omit(attributes, ["eargoSystems"])

        self.users.set(attributes.id, { ...attributes })
        if (eargoSystems.length) {
          const user = self.getUserById(attributes.id)
          eargoSystems.forEach((device) => user.addEargoSystem(device))
        }
      },
      addFirmwareVersions(attributes) {
        const isNull = !Object.values(attributes).some(
          (x) => x !== null && x !== ""
        )

        if (!isNull) {
          attributes = omit(attributes, ["ble"])
          Object.assign(self.firmwareVersions, {
            charger: attributes.charger,
            hi: attributes.hi,
          })
        }
      },
      reset() {
        apiClient.setUserId(null)
        self.setCurrentUserId("")
        self.salesforceUser = null
        if (self.eargoSystem?.eargoDevices.size) {
          self.state = INITIAL
          self.eargoSystems.eargoDevices.clear()
        }
      },
    }
  })

export default SessionStore
