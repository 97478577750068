import React, { useState } from "react"
import { Grid, Paper, Checkbox } from "@material-ui/core"
import styled from "styled-components"
import colors from "styles/colors"
import { observer } from "mobx-react"
import chartImage from "images/hearing_evaluation/chart.png"
import { hearingEvaluationImages } from "images/hearing_evaluation/hearing_avaluation_img"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import {
  useConfigurationStore,
  useHearingEvaluationStore,
  useUiStore,
} from "hooks/stores"
import HearingEvaluationHistory from "../hearing_evaluation_history/hearing_evaluation_history"
import { breakpoint } from "styled-components-breakpoint"
import HearingEvaluationChart from "../hearing_evaluation/hearing_evaluation_chart/hearing_evaluation_chart"

const Wrapper = styled.div`
  display: flex;
  width: 92%;
  min-width: 386px;
  margin: 0 auto;
`

const HeaderWrapper = styled.div`
  display: block;
  height: 56px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  margin-bottom: 10px;
`

const HeaderText = styled.div`
  display: inline-block;
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 24px;
  margin: 16px 0 16px 10px;
  text-transform: uppercase;
`

const HearingEvaluationImgWrapper = styled.div`
  margin: 0 auto;
  width: 382px;
  position: relative;
`

const HearingRightEarImg = styled.img`
  filter: invert(27%) sepia(55%) saturate(1679%) hue-rotate(186deg)
    brightness(104%) contrast(94%);
  position: absolute;
  left: 0;
  top: 0;
  width: 382px;
  z-index: 0;
`

const HearingLeftEarImg = styled.img`
  position: absolute;
  left: 0;
  top: 0;
  width: 382px;
  z-index: 0;
`

const StyledChartImage = styled.img`
  width: 382px;
  z-index: -1;
`

const CheckboxWrapper = styled.div`
  margin: 16px 26px 0 26px;
  padding-bottom: 16px;
`

const StyledPaper = styled(Paper)`
  && {
    background-color: ${colors.white};
    margin-right: 8px;

    ${breakpoint("mobile", "tablet")`
     max-width: 100%;
    `}
  }
`

const StyledPurpleCheckbox = styled(Checkbox)`
  && {
    color: ${colors.purple};

    &.Mui-checked {
      color: ${colors.purple};
    }
  }
`

const StyledBlueCheckbox = styled(Checkbox)`
  && {
    color: ${colors.blue};

    &.Mui-checked {
      color: ${colors.blue};
    }
  }
`

const CreateAudiogram = styled.div`
  display: inline-block;
  margin: 16px 28px 16px 0;
  float: right;
  color: ${colors.orange};
  cursor: pointer;
`

const HearingEvaluation = () => {
  const {
    leftHearingAssessmentGraph,
    rightHearingAssessmentGraph,
    isNewSoundMatch,
    setNewHearingAssessment,
    defaultNewSoundMatch,
  } = useHearingEvaluationStore()

  const {
    showConfigurationModal,
    showHearingAssessmentModal,
    openHearingAssessmentModal,
  } = useUiStore()

  const { applyForReset } = useConfigurationStore()

  const [checkboxRightEar, setCheckboxRightEar] = useState(true)
  const [checkboxLeftEar, setCheckboxLeftEar] = useState(true)

  const onRightEarCheckboxChange = () => {
    setCheckboxRightEar(!checkboxRightEar)
  }

  const onLeftEarCheckboxChange = () => {
    setCheckboxLeftEar(!checkboxLeftEar)
  }

  const handleOpenHearingAssessmentModal = () => {
    if (isNewSoundMatch) {
      openHearingAssessmentModal()
    } else {
      setNewHearingAssessment(defaultNewSoundMatch)
      openHearingAssessmentModal()
    }
  }

  const showCreateAudiogramButton =
    !applyForReset && showConfigurationModal && !showHearingAssessmentModal

  return (
    <Wrapper>
      <Grid container>
        <Grid item xs={12}>
          <StyledPaper>
            <HeaderWrapper>
              <HeaderText>Sound Match</HeaderText>
              {showCreateAudiogramButton && (
                <CreateAudiogram onClick={handleOpenHearingAssessmentModal}>
                  {isNewSoundMatch ? "Edit Audiogram" : "Enter Audiogram"}
                </CreateAudiogram>
              )}
            </HeaderWrapper>
            <HearingEvaluationImgWrapper>
              <HearingEvaluationChart
                checkboxRightEar={checkboxRightEar}
                checkboxLeftEar={checkboxLeftEar}
              />
              <StyledChartImage src={chartImage} alt="chart" />
              {checkboxRightEar &&
                hearingEvaluationImages(rightHearingAssessmentGraph) && (
                  <HearingRightEarImg
                    src={hearingEvaluationImages(rightHearingAssessmentGraph)}
                    alt="chart"
                  />
                )}
              {checkboxLeftEar &&
                hearingEvaluationImages(leftHearingAssessmentGraph) && (
                  <HearingLeftEarImg
                    src={hearingEvaluationImages(leftHearingAssessmentGraph)}
                    alt="chart"
                  />
                )}
            </HearingEvaluationImgWrapper>
            <CheckboxWrapper>
              <FormControlLabel
                control={
                  <StyledPurpleCheckbox
                    checked={checkboxRightEar}
                    onChange={onRightEarCheckboxChange}
                  />
                }
                label="Right ear"
              />
              <FormControlLabel
                control={
                  <StyledBlueCheckbox
                    checked={checkboxLeftEar}
                    onChange={onLeftEarCheckboxChange}
                  />
                }
                label="Left ear"
              />
            </CheckboxWrapper>
            {showConfigurationModal && !showHearingAssessmentModal && (
              <HearingEvaluationHistory />
            )}
          </StyledPaper>
        </Grid>
      </Grid>
    </Wrapper>
  )
}

export default observer(HearingEvaluation)
